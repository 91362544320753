#footer {
    padding-top: 80px;
    background: #000000;
    align-content: end;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
}

.ft-links a, .ft-info{
    font-family: Silka;
    color: #F6F6F6;
}

.ft-info {
    flex: 1;
    font-size: 48px;
}

.ft-links a {
    font-size: 40px;
}

.top-layer {
    display: flex;
    justify-content: space-between;
}

.a-reserved {
    color: #969696;
    font-size: 36px;
}

.bottom-layer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 60px !important;
    padding-bottom: 80px !important;
}

.bottom-layer div:nth-of-type(2) {
    display: flex;
    align-items: center;
    gap: 25px;
    text-align: right;
}

.footer-container {
    padding: 0 36px;
}

.ft-links {
    text-align: right;
    flex: 1;
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.svg-icon {
    fill: rgb(168, 168, 168);
    transition: fill 0.3s ease;
}

.svg-icon:hover,
.svg-icon:hover path,
.svg-icon:hover g,
.svg-icon:hover rect {
    fill: white;
}

@media (max-width: 1500px) {
    .ft-links a, .a-reserved, .ft-info {
        font-size: 2vw;
    }
}

@media (max-width: 768px) {
    .footer-container {
        padding: 0 20px;
    }

    #footer {
        padding-top: 40px;
    }

    .top-layer {
        flex-direction: column;

    }

    .ft-links {
        text-align: unset;
        font-size: 16px;
        display: flex;
        padding-top: 40px;
    }

    .ft-links div:nth-of-type(1) {
        flex: 2;
    }

    .ft-links div:nth-of-type(2) {
        flex: 1;
    }

    .ft-info {
        font-size: 20px !important;
    }

    .ft-links a, .a-reserved {
        font-size: 16px !important;
    }

    .bottom-layer div:nth-of-type(2) {
        gap: 10px;
    }

    .bottom-layer {
        padding-bottom: 40px !important;
        padding-top: 40px !important;
    }

    .ft-info:first-child {
        padding-bottom: 5px;
    }
}

@media (max-width: 420px) {
    .bottom-layer div:nth-of-type(2) {
        gap: 5px;
    }

    .a-reserved {
        font-size: 12px !important;
    }


}
