.work-details-section {
    display: flex;
    justify-content: space-between;
    padding-bottom:  100px;
    gap: 5px;
}

.d-title {
    font-size: 47px;
}

.d-title-s {
    padding-bottom: 40px;
    padding-top: 4px;
}

.main-image {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.description-inner-section {
    font-size: 30px;
}

.fade-in-w {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 0s linear 1s;
}

.fade-in-w.visible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
}

.contact-link {
    text-decoration: underline;
    font-size: 30px;
}

.wd-txt-t2 {
    font-size: 20px;
}

.wd-txt-t1 {
    font-size: 30px;
}

@media (max-width: 768px) {

    .wd-txt-t2 {
        font-size: 15px;
    }

    .wd-txt-t1 {
        font-size: 17px;
    }

    .description-inner-section {
        font-size: 17px;
    }

    .contact-link {
        font-size: 17px;
    }

    .main-image {
        height: 30vh;
    }

    .d-title {
        font-size: 20px;
    }

    .d-title-s {
        padding-bottom: 20px;
    }

    .work-details-section {
        flex-direction: column;
        padding-bottom: 40px;
    }

    .description-inner-section {
        padding-top: 20px;
    }
}
