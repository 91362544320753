.c-top-title {
    width: 67%;
    font-weight: 400;
    font-size: 47px;
    padding-top: 4px;
    padding-bottom: 120px;
}

.c-bottom-section {
    display: flex;
    gap: 80px;
    padding-bottom: 50px;
    padding-top: 100px;
}

.cm-section {
    display: flex;
    padding-top: 100px;
    gap: 80px;
}

.title-cm-contact {
    font-weight: 400;
    font-size: 47px;
    flex: 1;
}

.cm-txt-2 {
    font-size: 30px;
    padding-top: 46px;
}

.cb-txt-2 {
    font-size: 30px;
}

.form-section {
    padding-bottom: 100px;
}

@media (max-width: 768px) {
    .c-top-title {
        width: 100%;
    }

    .c-top-title, .cb-txt-2 {
        font-size: 30px;
        padding-bottom: 50px;
    }

    .cb-txt-2 {
        padding-top: 10px;
    }

    .cb-txt-1, .title-cm-contact {
        font-size: 16px;
    }

    .c-bottom-section {
        flex-direction: column;
        line-height: 1.4;
        gap: 50px;
    }

    .c-bottom-section div:nth-of-type(1) {
        line-height: 1;
    }

    .cm-section {
        flex-direction: column;
        padding-top: 50px;
        gap: 50px;
    }

    .title-cm-contact {
        padding-bottom: 0;
        font-size: 30px;
        font-weight: 400;
    }

    .cm-txt-2 {
        font-size: 17px;
        font-weight: 400;
        line-height: 22.95px;
    }

    .form-section {
        padding-bottom: 30px;
    }

    .c-bottom-section {
        padding-top: 50px;
        padding-bottom: 0;
    }

    .cb-txt-1 {
        font-size: 30px;
        font-weight: 400;
        line-height: 45px;
    }

    .cb-txt-2 {
        padding-bottom: 50px !important;
    }

    .cb-txt-2 p {
        font-size: 17px;
        font-weight: 400;
        line-height: 22.95px;
    }
}

@media (max-width: 430px) {
    .cb-txt-2 {
        font-size: 16px;
    }
}
