.c-us-form label {
    font-family: Silka;
    font-size: 25px;
}

.submit-btn {
    font-family: Silka-Bold;
    display: flex;
    background-color: transparent;
    border: none;
    align-items: center;
    font-size: 32px;
    cursor: pointer;
}

.disabled-btn {
    pointer-events: none;
    opacity: 1;
    color: #E3E3E3;
}

.project-details-s {
    margin-bottom: 64px;
}

.c-form-root {
    font-family: Silka;
    padding: 0 20px 20px 0;
    min-height: 400px;
}

.project-details-s  textarea {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
}

.contact-form-fs, .c-from-t-section, .c-form-s-section {
    padding-bottom: 62px;
}

@media (max-width: 768px) {
    .contact-form-fs, .c-from-t-section, .c-form-s-section {
        padding-bottom: 50px;
    }

    .contact-form-fs div:nth-of-type(1) {
        flex: 1 !important;
        padding-bottom: 50px;
    }

    .contact-form-fs div:nth-of-type(2) {
        flex: 1 !important;
    }

    .contact-form-fs {
        flex-direction: column !important;
    }

    .c-us-form label {
        font-size: 16px;
        line-height: 24px;
    }

    .submit-btn {
        font-size: 20px;
        line-height: 30px;
    }

    .project-details-s {
        margin-bottom: 30px;
    }

}
