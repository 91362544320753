.header-root {
    position: relative;
    width: 100%;
    height: 116px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px 36px 48px;
}

.m-menu {
    display: none;
}

.header-links {
    font-size: 32px;
    font-weight: 400;
}

a {
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

.link {
    text-decoration: none;
}

.link:hover,
.link-active {
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 3px;
}

@media (max-width: 768px) {
    .header-root {
        height: 125px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .header-links {
        font-size: 50px;
    }

    .d-menue {
        display: none;
    }

    .m-menu {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .arch-home {
        font-size: 35px;
    }

    .b-menue {
        height: 30px;
        max-width: 50px;
    }

    .mobile-menu-section {
        display: flex;
        height: calc(100vh - 125px);
        align-items: center;
        justify-content: center;
        font-size: 60px;
        padding-bottom: 50%;
    }

    .closeMenuImg {
        padding-right: 5px;
    }
}
