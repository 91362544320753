.gallery-item {
    position: relative;
    overflow: hidden;
    background: #fff;
    font-family: Silka;
    font-size: 30px;
    font-weight: 400;
}

.caption {
    text-align: left;
    font-size: 30px;
    font-family: Silka;
}

.gallery-item .image-wrapper {
    width: 100%;
    padding-top: 74.3%; /* This maintains a 16:9 aspect ratio */
    position: relative;
    max-height: 60vh; /* Restrict the maximum height */
    overflow: hidden; /* Hide overflow content */
}

.gallery-item .square-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.no-hover:hover {
    /* Disable hover effects */
    text-decoration: none; /* Remove underline on hover, if any */
    color: inherit; /* Maintain original color */
}

.no-hover .gallery-item:hover,
.no-hover .image-wrapper:hover,
.no-hover .square-image:hover,
.no-hover .caption:hover {
    /* Disable any other hover effects on child elements */
    background-color: inherit; /* Maintain original background color */
    border-color: inherit; /* Maintain original border color */
    /* Add any other properties to override specific hover effects */
}


@media (max-width: 768px) {
    .caption {
        font-size: 20px;
    }
}
