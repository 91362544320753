.home-top-title {
    width: 70%;
    font-size: 60px;
    padding: 80px 0;
    min-height: 140px;
    line-height: inherit;
}

.more-works-section {
    padding-top: 60px;
    padding-bottom: 100px;
    display: flex;
    justify-content: end;
}

.o-latest-res {
    padding-bottom: 24px;
    padding-top: 100px;
    min-height: 60px;
}

.o-latest-res span {
    font-size: 36px;
    font-family: Silka;
}

.more-works-txt {
    font-size: 40px;
    font-weight: 400;
    line-height: inherit;
    min-height: 40px;
}

.arrow-up {
    width: 24px;
}

.carousel-image {
    margin: 0;
    height: calc(100vh - 116px);
    color: #fff;
    line-height: 160px;
    text-align: center;
    object-fit: cover;
}

@media (max-width: 768px) {
    .gallery-item {
        max-height: 100%; /* Allow full height on smaller screens */
    }

    .home-top-title {
        width: 100%;
        font-size: 30px;
        padding: 50px 0;
    }

    .o-latest-res {
        padding-top: 50px;
        padding-bottom: 30px;
    }

    .o-latest-res span {
        font-size: 25px;
    }


    .more-works-section {
        padding-top: 40px;
        padding-bottom: 50px;
    }

    .more-works-txt {
        font-size: 20px;
    }

    .arrow-up {
        width: 13px;
    }

    .carousel-image {
        margin: 0;
        height: calc(50vh - 153px);
        color: #fff;
        line-height: 160px;
        text-align: center;
        object-fit: cover;
    }
}
