.category-button {
    text-decoration: none;
    color: #000;
    font-size: 20px;
    font-family: Silka;
    border: none;
    background: none;
    cursor: pointer;
    padding: 10px 20px;
}

.category-button:hover, .category-button:active, .category-button.active{
    text-decoration: underline;
    text-underline-offset: 3px;
}

.work-categories {
    text-align: right;
    padding-bottom: 100px;
}

.works-title {
    padding-top: 4px;
    padding-bottom: 60px;
    font-size: 47px;
    font-family: Silka-Medium;
    width: 65%;
}

.load-works-section {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-bottom: 100px;
}

.works-title-m {
    display: none;
}

.load-more:hover {
    text-decoration: underline;
    cursor: pointer;
    text-underline-offset: 3px;
    text-decoration-thickness: 2px;
}

.work-categories > div {
    display: inline-block;
}

.load-more span {
    font-size: 40px;
}


@media (max-width: 768px) {
    .load-more span {
        font-size: 20px;
    }

    .works-title {
        width: 100%;
    }

    .works-title p {
        font-size: 30px;
    }

    .work-categories {
        text-align: unset;
        padding-bottom: 50px;
    }

    .work-categories > div {
        display: flex;
        justify-content: space-between;
    }

    .works-title-m {
        font-family: Silka;
        font-weight: 600;
        font-size: 20px;
        padding-top: 30px;
        padding-bottom: 15px;
    }

    .load-works-section {
        display: flex;
        align-items: center;
        padding-bottom: 50px;
    }

    .category-button {
        font-size: 15px;
        padding: 5px 10px;
    }

}
