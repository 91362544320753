.au-first-s {
    display: flex;
    justify-content: space-between;
    padding-bottom: 120px;
    gap: 40px;
}

.au-first-s div:nth-of-type(1) {
    font-size: 47px;
    line-height: 1.4;
    flex: 1;
    font-weight: 400;
}

.au-first-s div:nth-of-type(2) {
    flex: 1;
}

.af-2-txt {
    flex: 1;
    font-size: 30px;
    font-weight: 400;
}

.au-second-s {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    padding-top: 100px;
    padding-bottom: 150px;
}

.au-second-s p {
    font-size: 30px;
    font-weight: 400;
}

.au-third-s {
    display: flex;
    justify-content: space-between;
    gap: 80px;
    padding-top: 100px;
    padding-bottom: 120px;
}

.title-unleash-c {
    font-size: 47px;
    font-weight: 400;
}

.title-our-services {
    font-size: 47px;
    font-weight: 400;
}

.about-us-t-section {
    padding-top: 4px;
    font-weight: 400;
}

.uc-section {
    padding-bottom: 150px;
}

.uc-section span {
    font-size: 30px;
}

.em-s {
    font-size: 47px;
}

#our-services {
    display: flex;
    gap: 80px;
    padding-top: 100px;
    padding-bottom: 120px;
}

.explore-more {
    text-underline-offset: unset;
    text-decoration: unset;
}

.about-us-arrow-up {
    display: inline-block;
    margin-left: 30px;
}

.custom-collapse .ant-collapse-header {
    color: black;
    font-weight: 500;
    font-size: 30px;
}

.ant-collapse-header {
    border-bottom: 1px solid #D1D1D1;
    padding: 0 !important;
    padding-bottom: 10px !important;
    padding-top: 10px !important;
    align-items: center !important;
}

.custom-collapse .ant-collapse-item:last-child .ant-collapse-header {
    border-bottom: 0 !important;
}

.custom-collapse .ant-collapse-header {
    user-select: none;
}


.custom-arrow {
    transition: transform 0.2s ease-in-out;
    transform: rotate(180deg);
}

.custom-arrow.rotate {
    transform: rotate(0deg); /* Rotates the arrow up when active */
}


.ant-collapse-content-box {
    padding-top: 30px !important;
    padding-bottom: 20px !important;
    padding-left: 0 !important;
}

.custom-collapse .ant-collapse-arrow {
    right: 0; /* Adjust the position of the arrow */
}

.ant-collapse-arrow > svg{
    width: 24px;
    height: 24px;
}

.custom-collapse p {
    font-family: Silka;
    font-size: 30px;
    font-weight: 400;
}

.ant-collapse-header-text {
    font-family: Silka-Medium;
    font-size: 30px;
}

@media (max-width: 1570px) {
    .about-us-arrow-up {
        display: none;
        margin-left: 30px;
    }
}

@media (max-width: 768px) {

    .uc-section span {
        font-size: 17px;
    }

    .uc-section {
        padding-bottom: 40px;
    }

    .af-2-txt {
        text-align: left;
        font-size: 17px;
        line-height: 25.5px;
    }

    .au-first-s {
        flex-direction: column;
        padding-bottom: 50px;
    }

    .au-second-s {
        padding-top: 50px;
        flex-direction: column;
        gap: 40px;
        padding-bottom: 50px;
    }

    .au-second-s p {
        font-size: 17px;
        line-height: 25.5px;
    }

    .au-third-s {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding-bottom: 54px;
        padding-top: 50px;
        gap: 40px;
    }

    .em-s {
        font-size: 24px;
    }

    #our-services {
        flex-direction: column;
        padding-top: 40px;
        padding-bottom: 50px;
        gap: 40px;
    }

    .au-first-s div:first-of-type {
        font-size: 30px;
    }

    .title-unleash-c, .title-our-services {
        font-size: 30px !important;
    }

    #our-services:last-child p {

        font-size: 17px;
        line-height: 28.9px;
    }
}
