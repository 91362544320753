.main-t-styles {
    padding-left: 36px;
    padding-right: 36px;
}

.arrow-up, .arrow-down {
    width: 24px;
}

.fade-in-element {
    opacity: 0;
    transition: opacity 1.5s ease-in-out, transform 1.5s ease-in-out;
}

.fade-in-element.visible {
    opacity: 1;
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease;
}

.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease;
}

@media (max-width: 768px) {
    .arrow-up, .arrow-down {
        width: 13px;
    }

    .main-t-styles {
        padding-left: 20px;
        padding-right: 20px;
    }
}
